export default class MenuController {
  constructor(
    $scope,
    $state,
    $uibModal,
    $uibModalStack,
    $document,
    $timeout,
    $log,
    personalContact,
    CtiProxy,
    XucLink,
    $window,
    JitsiProxy,
    mediaDevices,
    localStorageService,
    processVolume,
    XucPhoneState,
    WebHIDHeadsetService,
    $rootScope
  ) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$uibModal = $uibModal;
    this.$uibModalStack = $uibModalStack;
    this.$document = $document;
    this.$timeout = $timeout;
    this.stopTimeout;
    this.$log = $log;
    this.personalContact = personalContact;
    this.ctiProxy = CtiProxy;
    this.xucLink = XucLink;
    this.$window = $window;
    this.jitsiProxy = JitsiProxy;
    this.mediaDevices = mediaDevices;
    this.localStorageService = localStorageService;
    this.processVolume = processVolume;
    this.xucPhoneState = XucPhoneState;
    this.webHIDHeadsetService = WebHIDHeadsetService;

    this.$scope.$watch("importFile", this.import.bind(this));
    this.$scope.active= !this.isWebRtc() ? 1 : 0;
    this.$scope.headsetName = this.webHIDHeadsetService.getCurrentDeviceName();
    this.$rootScope.$on(WebHIDHeadsetService.HEADSET_SELECTED, (_, device) => {
      console.debug(`Received ${device} in MenuController`);
      this.$scope.headsetName = device;
      if (!this.$rootScope.$$phase) this.$scope.$apply();
    });


    this.getAudioDevices();
  }

  initWebHID() {
    this.webHIDHeadsetService.init();
  }

  getAudioInputStatus() {
    return this.xucPhoneState.getDeviceConference().calls.length > 0 ? 'disabled' : '';
  }

  getAudioDevices() {
    this.mediaDevices
      .getAudioDevices()
      .then(this.processAudioDevices.bind(this))
      .catch((err) => {
        this.$log.error("Error when getting devices", err);
      });
  }

  processAudioDevices(devices) {
    this.setAudioOutputDevices(devices);
    this.setAudioInputDevices(devices);
  }

  setAudioOutputDevices(devices) {
    this.audioDevices = this.filterDevicesByKind(devices, "audiooutput");
    this.$scope.selectedAudioDevice = this.selectDevice(
      this.audioDevices,
      "AudioOutputDeviceId"
    );
  }

  setAudioInputDevices(devices) {
    this.microphoneDevices = this.filterDevicesByKind(devices, "audioinput");
    this.$scope.selectedMicrophoneDevice = this.selectDevice(
      this.microphoneDevices,
      "AudioInputDeviceId"
    );
  }

  filterDevicesByKind(devices, kind) {
    return devices.filter((device) => device.kind === kind);
  }

  selectDevice(devices, storageKey) {
    const storedId = this.localStorageService.get(storageKey);
    return (
      devices.find((device) => device.deviceId == storedId) ||
      devices.find((device) => device.deviceId == "default")
    );
  }

  changeAudioOutputDevice(device) {
    xc_webrtc.applyAudioOutputToElements(device);
    this.localStorageService.set("AudioOutputDeviceId", device);
    this.$scope.selectedAudioDevice = this.audioDevices.find(
      (dev) => dev.deviceId == device
    );
  }

  changeAudioInputDevice(device) {
    this.$window.SIPml.setGlobalMicrophoneDeviceId(device);
    xc_webrtc
      .swapCurrentMicrophone(device)
      .then((newStream) => {
        this.processVolume.onMicChange(newStream);
      })
      .catch((err) => {
        this.$log.debug(err);
      });
    this.localStorageService.set("AudioInputDeviceId", device);
    this.$scope.selectedMicrophoneDevice = this.microphoneDevices.find(
      (dev) => dev.deviceId == device
    );
  }

  isWebRtc() {
    return this.ctiProxy.isUsingWebRtc();
  }

  close(dest) {
    this.$state.go(dest);
    this.$uibModalStack.dismissAll();
  }

  remove() {
    this.personalContact.removeAll().then(() => {
      this.close("interface.favorites");
    });
  }

  uploadFile() {
    this.stopTimeout = this.$timeout(() => {
      // force ongoing apply to be sequenced
      this.$document.find("#import-contacts").click();
      this.$scope.importFile = false; // set a transient value to trigger $watch
    }, 0);
  }

  import(newFile, oldFile) {
    this.$timeout.cancel(this.stopTimeout);
    if (newFile === oldFile || !newFile) return;
    this.$scope.ajaxRequest = {
      ongoing: true,
      error: false,
    };
    this.personalContact
      .import(newFile, this.$scope)
      .then(
        (response) => {
          this.$scope.ajaxRequest.success = true;
          this.$scope.imported = response.data;
        },
        (response) => {
          if (response.data.error) {
            this.$scope.ajaxRequest["error" + response.data.error] = true;
          } else {
            this.$scope.ajaxRequest.error = true;
          }
        }
      )
      .finally(() => {
        this.$scope.ajaxRequest.ongoing = false;
        delete this.$scope.importFile;
      });
  }

  export() {
    this.personalContact.export();
  }

  confirmDelete() {
    this.$uibModal.open({
      templateUrl: "confirmDialog.html",
      size: "sm",
      scope: this.$scope,
      controller: "ConfirmationModalController",
      controllerAs: "modalCtrl",
      windowClass: "remove-all-contact-modal",
      resolve: {
        params: () => {
          return { deleteAll: true, item: "CONTACT" };
        },
      },
    });
  }

  logout() {
    if (this.$window.externalConfig.useSso) this.xucLink.clearCredentials();
    if (this.jitsiProxy.videoIsOngoing()) this.jitsiProxy.dismissVideo();
    this.$uibModalStack.dismissAll();
    this.xucLink.logout();
    this.ctiProxy.stopUsingWebRtc();
    this.$state.go("login", {
      error: "Logout",
    });
  }

  isJitsiAvailable() {
    return this.jitsiProxy.jitsiAvailable;
  }
}
