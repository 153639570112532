"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserGroupService = void 0;
const UserGroup_model_1 = require("../../xchelper/models/UserGroup.model");
class UserGroupService {
    get userGroups() {
        return this._userGroups;
    }
    constructor($window, $log, XucLink, $rootScope, remoteConfiguration) {
        this.$window = $window;
        this.$log = $log;
        this.XucLink = XucLink;
        this.$rootScope = $rootScope;
        this.remoteConfiguration = remoteConfiguration;
        this._userGroups = undefined;
        this._showPauseActions = true;
        this.unInit = () => {
            this.$log.info("Unloading UserGroup service");
            this._userGroups = undefined;
        };
        this.canShowPauseActions = () => {
            return this._showPauseActions;
        };
        this.handleNewGroup = (newGroup) => {
            var _a;
            if (!this._userGroups ||
                (!((_a = this._userGroups) === null || _a === void 0 ? void 0 : _a.some((group) => group.groupId === newGroup.groupId)) &&
                    newGroup.membershipStatus !== UserGroup_model_1.MembershipStatus.Exited)) {
                this._userGroups = [...(this._userGroups || []), newGroup];
                return true;
            }
            return false;
        };
        this.handleExistingGroup = (groupUpdate) => {
            var _a;
            this._userGroups = (_a = this._userGroups) === null || _a === void 0 ? void 0 : _a.filter((group) => group.groupId !== groupUpdate.groupId ||
                groupUpdate.membershipStatus !== UserGroup_model_1.MembershipStatus.Exited).map((group) => group.groupId === groupUpdate.groupId
                ? Object.assign(Object.assign({}, group), groupUpdate) : group);
        };
    }
    init() {
        var _a, _b;
        this.$log.info("Starting UserGroup service");
        this.XucLink.whenLoggedOut().then(this.unInit);
        (_a = this.$window.Cti) === null || _a === void 0 ? void 0 : _a.setHandler(this.$window.Cti.MessageType.USERGROUPS, this.onGetUserGroups.bind(this));
        (_b = this.$window.Cti) === null || _b === void 0 ? void 0 : _b.setHandler(this.$window.Cti.MessageType.USERGROUPUPDATE, this.onGetUserGroupUpdate.bind(this));
    }
    onGetUserGroups(userGroups) {
        this._userGroups = userGroups;
        this.remoteConfiguration.getBoolean("ucShowGroupControl").then((value) => this._showPauseActions = value);
    }
    onGetUserGroupUpdate(userGroup) {
        const isNew = this.handleNewGroup(userGroup);
        if (!isNew) {
            this.handleExistingGroup(userGroup);
        }
        this.$rootScope.$broadcast('userGroupUpdate');
    }
    callGroup(groupNumber) {
        var _a;
        (_a = this.$window) === null || _a === void 0 ? void 0 : _a.Cti.dial(groupNumber);
    }
    pauseGroup(groupId) {
        var _a;
        (_a = this.$window) === null || _a === void 0 ? void 0 : _a.Cti.pauseUserGroup(groupId);
    }
    unpauseGroup(groupId) {
        var _a;
        (_a = this.$window) === null || _a === void 0 ? void 0 : _a.Cti.unpauseUserGroup(groupId);
    }
}
exports.UserGroupService = UserGroupService;
UserGroupService.$inject = ["$window", "$log", "XucLink", "$rootScope", "remoteConfiguration"];
