"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserGroupController = void 0;
const UserGroup_model_1 = require("xchelper/models/UserGroup.model");
class UserGroupController {
    constructor($scope, $rootScope, $state, $translate, UserGroupService, debounce) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.$translate = $translate;
        this.UserGroupService = UserGroupService;
        this.debounce = debounce;
        this.groups = undefined;
        this.toggleGroupMembership = this.debounce((group) => {
            const isPaused = group.membershipStatus === 'Paused';
            const action = isPaused ? 'join' : 'leave';
            this.toggleClickAnimation(`${action}Group-${group.groupId}`, 'clicked', 10000);
            (isPaused ? this.unpauseGroup : this.pauseGroup)(group.groupId);
        });
        this.callGroup = this.debounce((groupNumber) => {
            this.UserGroupService.callGroup(groupNumber);
        });
        this.pauseGroup = this.debounce((groupId) => {
            this.UserGroupService.pauseGroup(groupId);
        });
        this.unpauseGroup = this.debounce((groupId) => {
            this.UserGroupService.unpauseGroup(groupId);
        });
        this.initializeGroups();
        this.$rootScope.$on('userGroupUpdate', () => {
            this.initializeGroups();
            if (!this.groups || this.groups.length == 0) {
                this.$state.go('interface.favorites');
            }
        });
    }
    canShowPauseActions() {
        return this.UserGroupService.canShowPauseActions();
    }
    toggleClickAnimation(elementId, className, duration) {
        const element = document.getElementById(elementId);
        if (!element)
            return;
        element.classList.add(className);
        setTimeout(() => element.classList.remove(className), duration);
    }
    initializeGroups() {
        var _a;
        this.groups = (_a = this.UserGroupService.userGroups) === null || _a === void 0 ? void 0 : _a.sort((a, b) => {
            if (a.membershipStatus === UserGroup_model_1.MembershipStatus.Available && b.membershipStatus !== UserGroup_model_1.MembershipStatus.Available) {
                return -1;
            }
            if (b.membershipStatus === UserGroup_model_1.MembershipStatus.Available && a.membershipStatus !== UserGroup_model_1.MembershipStatus.Available) {
                return 1;
            }
            return a.groupName.localeCompare(b.groupName);
        });
    }
}
exports.UserGroupController = UserGroupController;
UserGroupController.$inject = [
    "$scope",
    "$rootScope",
    "$state",
    "$translate",
    "UserGroupService",
    "debounce"
];
