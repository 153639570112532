export default class InitController {

  constructor($scope, $rootScope, $state, $log, errorModal, XucPhoneState, CtiProxy, XucLink, XucSheet, ExternalEvent, electronWrapper, XucNotification, JitsiProxy, ContactService) {

    this.XucSheet = XucSheet;
    this.jitsiProxy = JitsiProxy;

    CtiProxy.disableMediaKeys();
    

    $scope.getPhoneStateBackColor = (contact) => ContactService.getPhoneStateBackColor(contact);

    $scope.getPhoneStateLabel = (contact) => ContactService.getPhoneStateLabel(contact);

    XucNotification.enableCallNotification();
    XucNotification.enableChatNotification();
    
    ExternalEvent.registerConfirmQuitCallback(() => {
      XucPhoneState.getCalls().map(call =>
        CtiProxy.hangup(call.uniqueId)
      );
      CtiProxy.stopUsingWebRtc();
      electronWrapper.forceQuit();
    });

    this.jitsiProxy.subscribeToCloseVideo($scope, () => {
      if ($scope.phoneBarIsVisible() == false) {
        this.jitsiProxy.togglePhoneBar();
      }
      if (!$rootScope.$$phase) $scope.$apply();
    });
    
    $scope.phoneBarIsVisible = () => {
      return this.jitsiProxy.getShowPhoneBar();
    };
    
    $scope.$on('linkDisConnected', function () {
      $log.warn('InitController - Received linkDisConnected');
      if (CtiProxy.isUsingWebRtc() && XucPhoneState.getCalls().length > 0) {
        $log.warn('CTI WS closed when CtiProxy is using WebRtc');
        errorModal.showErrorModal('CTI_SOCKET_CLOSED_WHILE_USING_WEBRTC');
      } else {
        if (CtiProxy.isUsingWebRtc()) {
          CtiProxy.stopUsingWebRtc();
        }
        XucLink.logout();
        $state.go('login', { 'error': 'LinkClosed' });
      }
    });
  }
}
