"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeadsetsProvider = void 0;
class HeadsetsProvider {
    constructor(configProvider) {
        this.configProvider = configProvider;
    }
    getHeadsetModelByVendorID(vendorId) {
        return this.configProvider
            .getCurrentAppConfig()
            .headsetSupportedVendors
            .find(v => v == vendorId);
    }
    getSupportedVendors() {
        return this.configProvider.getCurrentAppConfig().headsetSupportedVendors;
    }
}
exports.HeadsetsProvider = HeadsetsProvider;
