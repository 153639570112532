"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const WebHIDHeadsets_model_1 = require("xchelper/models/WebHIDHeadsets.model");
class WebHIDHeadsetService {
    constructor($window, $log, $rootScope, XucPhoneEventListener, CtiProxy, electronWrapper, applicationConfiguration, localStorageService) {
        this.$window = $window;
        this.$log = $log;
        this.$rootScope = $rootScope;
        this.XucPhoneEventListener = XucPhoneEventListener;
        this.CtiProxy = CtiProxy;
        this.electronWrapper = electronWrapper;
        this.applicationConfiguration = applicationConfiguration;
        this.localStorageService = localStorageService;
        this.ringing = false;
        this.established = false;
        this.HEADSET_SELECTED = "WEBHID_DEVICE_SELECTED";
        this.getCurrentDeviceName = () => {
            return this.headsetName;
        };
        this.setCurrentDeviceName = (name) => {
            return this.headsetName = name;
        };
        this.processEvent = (eventCode, reportId, electronReport) => {
            console.debug(`Web HID event ${eventCode} on report ${reportId} for vendor ${this.headsetModel}`);
            let report = electronReport || this.getWebReport(reportId);
            let matchingAction = this.getMatchingAction(eventCode, report);
            if (matchingAction)
                this.executeMatchingAction(matchingAction);
        };
        this.getWebReport = (reportId) => {
            var _a, _b;
            let report = (_a = this.device) === null || _a === void 0 ? void 0 : _a.collections.flatMap(col => col.inputReports).find(report => report.reportId === reportId);
            if (report && ((_b = report.items) === null || _b === void 0 ? void 0 : _b.length) > 1) {
                report.items[0].usages = report.items.reduce((acc, item) => acc.concat(item.usages), []);
                report.items = [report.items[0]];
            }
            return report;
        };
        this.getMatchingAction = (eventCode, report) => {
            var _a;
            let truthyBitPosition = this.firstTruthyBitPos(eventCode);
            for (const item of (report === null || report === void 0 ? void 0 : report.items) || []) {
                for (let i = 0; i < (((_a = item.usages) === null || _a === void 0 ? void 0 : _a.length) || 0); i++) {
                    if (i === truthyBitPosition) {
                        return item.usages[i];
                    }
                }
            }
        };
        this.firstTruthyBitPos = (nb) => {
            if (!nb || nb == 0)
                return -1;
            let position = 0;
            while ((nb & 1) === 0) {
                nb >>= 1;
                position++;
            }
            return position;
        };
        this.executeMatchingAction = (encodedAction) => {
            let [page, action] = this.toHIDCode(encodedAction);
            console.debug("Received action", `${page}:${action}`);
            switch (page) {
                case "B":
                    switch (action) {
                        case "20":
                            this.hookSwitch();
                            break;
                    }
                    break;
                case "C":
                    switch (action) {
                        case "B0":
                        case "B1":
                        case "CD":
                            this.hookSwitch();
                            break;
                    }
                    break;
            }
        };
        this.toHIDCode = (action) => {
            let decoded = action
                .toString(16)
                .toUpperCase();
            let usage = decoded.slice(-2);
            let page = decoded.slice(0, -4);
            return [page, usage];
        };
        this.hookSwitch = () => {
            if (this.ringing)
                this.answer();
            else if (this.established)
                this.hangup();
        };
        this.answer = () => {
            this.CtiProxy.answer();
        };
        this.hangup = () => {
            this.CtiProxy.hangup();
        };
        this.$log.info("Starting webHID headset service");
        this.headsetProvider = new WebHIDHeadsets_model_1.HeadsetsProvider(this.applicationConfiguration);
        this.XucPhoneEventListener.addRingingHandler($rootScope, () => { this.ringing = true; });
        this.XucPhoneEventListener.addEstablishedHandler($rootScope, () => { this.ringing = false; this.established = true; });
        this.XucPhoneEventListener.addReleasedHandler($rootScope, () => { this.ringing = false; this.established = false; });
        this.$rootScope.$on("WEBHID_INPUT", this.processElectronHIDInput.bind(this));
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            let filters = this.headsetProvider.getSupportedVendors();
            this.electronWrapper.isElectron() ? this.electronHandler(filters) : this.webHandler(filters);
        });
    }
    setInputListener(device) {
        device.addEventListener('inputreport', (e) => {
            const { data, reportId } = e;
            let eventCode;
            if (data.buffer) {
                let uia = new Uint8Array(data.buffer);
                eventCode = uia[0];
            }
            else
                eventCode = data.getUint8(0);
            if (eventCode == 0)
                return;
            this.processEvent(eventCode, reportId);
        });
    }
    electronHandler(filters) {
        this.electronWrapper.requestWebHID(filters);
    }
    processElectronHIDInput(_, data) {
        console.debug("Received external HID input", data);
        this.headsetModel = this.headsetProvider.getHeadsetModelByVendorID(data.vendorId);
        if (this.headsetModel) {
            this.processEvent(data.code, data.reportId, data.report);
        }
        else
            console.warn(`This device is not supported for webHID`);
    }
    webHandler(filters) {
        return __awaiter(this, void 0, void 0, function* () {
            filters = filters.map(filter => { return { vendorId: filter }; });
            let [device] = yield this.$window.navigator.hid.requestDevice({ filters });
            if (device) {
                this.headsetModel = this.headsetProvider.getHeadsetModelByVendorID(device.vendorId);
                if (this.headsetModel) {
                    yield this.openDevice(device);
                    this.localStorageService.set("HID_DEVICE", { vendorId: device.vendorId, productId: device.productId });
                }
                else
                    console.warn(`This device is not supported for webHID: ${device.productName}`);
            }
        });
    }
    openDevice(device) {
        return __awaiter(this, void 0, void 0, function* () {
            if (device) {
                try {
                    yield device.open();
                    this.headsetModel = device.vendorId;
                    this.device = device;
                    this.headsetName = this.device.productName;
                    this.$rootScope.$broadcast(this.HEADSET_SELECTED, this.headsetName);
                    this.setInputListener(this.device);
                }
                catch (e) {
                    console.warn(`Could not open WebHID device ${device.productName}`);
                    console.debug("Reason :", e);
                }
            }
        });
    }
    getDeviceFromBrowser(device) {
        return this.$window.navigator.hid.getDevices().then((devices) => {
            return devices.find((d) => d.vendorId == device.vendorId && d.productId == device.productId);
        });
    }
}
exports.default = WebHIDHeadsetService;
WebHIDHeadsetService.$inject = ["$window", "$log", "$rootScope", "XucPhoneEventListener", "CtiProxy", "electronWrapper", "applicationConfiguration", "localStorageService"];
