"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const userPreference_model_1 = require("../models/userPreference.model");
class UserPreferenceService {
    get userPreferences() {
        return this._userPreferences;
    }
    constructor($window, $uibModal, $log, remoteConfiguration, XucLink, $rootScope) {
        this.$window = $window;
        this.$uibModal = $uibModal;
        this.$log = $log;
        this.remoteConfiguration = remoteConfiguration;
        this.XucLink = XucLink;
        this.$rootScope = $rootScope;
        this._userPreferences = undefined;
        this.opened = false;
        this.modalInstance = undefined;
        this.timeout = 15000;
        this.timer = undefined;
        this.NEW_NB_MISSED_CALLS = "newNbMissedCalls";
        this.unInit = () => {
            this.$log.info("Unloading UserPreference service");
            this._userPreferences = undefined;
        };
    }
    init() {
        var _a;
        this.$log.info("Starting UserPreference service");
        this.XucLink.whenLoggedOut().then(this.unInit);
        (_a = this.$window.Cti) === null || _a === void 0 ? void 0 : _a.setHandler(this.$window.Cti.MessageType.USERPREFERENCE, this.onGetUserPreference.bind(this));
    }
    onGetUserPreference(userPreference) {
        var _a;
        this._userPreferences = userPreference;
        if (!(0, lodash_1.isNil)(this._userPreferences) &&
            !(0, lodash_1.isNil)(this._userPreferences[userPreference_model_1.UserPreferenceKey.NbMissedCall]) &&
            !isNaN(parseInt(this._userPreferences[userPreference_model_1.UserPreferenceKey.NbMissedCall].value))) {
            this.$rootScope.$broadcast(this.NEW_NB_MISSED_CALLS, parseInt(this._userPreferences[userPreference_model_1.UserPreferenceKey.NbMissedCall].value));
        }
        if (this.opened)
            return;
        if (!(0, lodash_1.isNil)(this._userPreferences) &&
            !(0, lodash_1.isNil)(this._userPreferences[userPreference_model_1.UserPreferenceKey.MobileAppInfo]) &&
            this._userPreferences[userPreference_model_1.UserPreferenceKey.MobileAppInfo].value === userPreference_model_1.UserPreferenceBoolean.True &&
            !((_a = this.remoteConfiguration) === null || _a === void 0 ? void 0 : _a.isAgent()) &&
            !this.remoteConfiguration.hasSwitchBoard()) {
            this.modalInstance = this.$uibModal.open({
                templateUrl: 'assets/javascripts/xchelper/controllers/notificationModal.controller.html',
                size: 'sm',
                controller: 'NotificationModalController',
                resolve: {}
            });
            this.opened = true;
            this.modalInstance.result.catch(() => {
                if (this.timer)
                    clearTimeout(this.timer);
                this.$log.debug("modal closed");
                this.modalInstance = undefined;
                this.onChangeMobileAppInfo(userPreference_model_1.UserPreferenceBoolean.False);
                this.opened = false;
            });
        }
    }
    onCleanUserPreference() {
        this._userPreferences = undefined;
    }
    onChangeUserPreference(key, value, value_type) {
        var _a;
        (_a = this.$window.Cti) === null || _a === void 0 ? void 0 : _a.setUserPreference(key, value, value_type);
    }
    onChangePreferredDevice(value) {
        var _a;
        (_a = this.$window.Cti) === null || _a === void 0 ? void 0 : _a.setUserPreference(userPreference_model_1.UserPreferenceKey.PreferredDevice, value, userPreference_model_1.UserPreferenceValueType.string);
    }
    onChangeMobileAppInfo(value) {
        var _a;
        (_a = this.$window.Cti) === null || _a === void 0 ? void 0 : _a.setUserPreference(userPreference_model_1.UserPreferenceKey.MobileAppInfo, value, userPreference_model_1.UserPreferenceValueType.boolean);
    }
    resetMissedCalls() {
        var _a;
        (_a = this.$window.Cti) === null || _a === void 0 ? void 0 : _a.setUserPreference(userPreference_model_1.UserPreferenceKey.NbMissedCall, "0", userPreference_model_1.UserPreferenceValueType.string);
    }
    getPreferredDevice() {
        return (this.userPreferences && this.userPreferences[userPreference_model_1.UserPreferenceKey.PreferredDevice]) ?
            this.userPreferences[userPreference_model_1.UserPreferenceKey.PreferredDevice].value :
            userPreference_model_1.UserPreferencePreferredDevice.WebAppAndMobileApp;
    }
    getMissedCalls() {
        return (this.userPreferences &&
            this.userPreferences[userPreference_model_1.UserPreferenceKey.NbMissedCall] &&
            !isNaN(parseInt(this.userPreferences[userPreference_model_1.UserPreferenceKey.NbMissedCall].value)))
            ? parseInt(this.userPreferences[userPreference_model_1.UserPreferenceKey.NbMissedCall].value)
            : 0;
    }
}
exports.default = UserPreferenceService;
UserPreferenceService.$inject = ["$window", "$uibModal", "$log", "remoteConfiguration", "XucLink", "$rootScope"];
