import _ from 'lodash';

export default function electronWrapper($window, $translate) {
  const obj = $window;
  const path = 'setElectronConfig';
  const ELECTRON_AGENT_DEFAULT_WIDTH = 362;
  const ELECTRON_AGENT_DEFAULT_HEIGHT = 800;
  const ELECTRON_AGENT_MINI_WIDTH = 72;
  const ELECTRON_AGENT_MINI_HEIGHT = 675;
  const ELECTRON_AGENT_THIRD_PARTY_MIN_WIDTH = 1000;
  const ELECTRON_AGENT_QUIT_OPTIONS = {
    type: 'question',
    buttons: ['AGT_DIALOG_CANCEL', 'AGT_DIALOG_QUIT'],
    defaultId: 1,
    message: 'AGT_DIALOG_MSG',
    detail: 'AGT_DIALOG_DETAIL'
  };
  
  let currentIcon;
  let windowToggled = false;

  const _isElectron = () => {
    return _.isFunction(_.get(obj, path));
  };

  const _setFocus = () => {
    _execInElectronContext({focus : true});
  };

  const _forceQuit = () => {
    _execInElectronContext({forceQuit : true});
  };

  const _setTrayIcon = (icon) => {
    if (icon != currentIcon) {
      _execInElectronContext({trayIcon : icon});
    }
    currentIcon = icon;
  };

  const _runExecutable = (path, args) => {
    _execInElectronContext({runExecutable: path, executableArgs: args});
  };

  const _startJitsi = (roomuuid, roomdisplayname, roomtoken, username) => {
    _execInElectronContext({startJitsi: {uuid: roomuuid, displayname: roomdisplayname, token: roomtoken, user: username}});
  };

  const _muteJitsi = () => {
    _execInElectronContext({muteJitsi: true});
  };

  const _requestWebHID = (filters) => {
    _execInElectronContext({requestWebHID: true, filters: filters});
  };

  const _toggleWindow = (toggledSize, defaultSize) => {
    if (!windowToggled){
      _setElectronConfig(toggledSize);
    } else {
      _setElectronConfig(defaultSize);
    }
    windowToggled = !windowToggled;
  };

  const _isWindowToggled = () => {
    return windowToggled;
  };

  const _setElectronConfig = ({width, height, title, minimalist = false, confirmQuit}) => {
    let data = {};
    Object.assign(data,
      title && {title: title},
      (width && height) && {size: {w: width, h: height, mini: minimalist}},
      confirmQuit && {confirmQuit: translateDialog(confirmQuit)}
    );
    _execInElectronContext(data);
  };

  const translateDialog = (confirmQuit) => {
    let dialog = {};

    angular.forEach(confirmQuit, (value, key) => {
      switch (key) {
      case 'message':
      case 'detail':
        dialog[key] = $translate.instant(value);
        break;

      case 'buttons':
        dialog[key] = value.map(i => $translate.instant(i));
        break;

      default:
        dialog[key] = value;
      }
    });

    return dialog;
  };

  const _setElectronFullscreen = () => {
    _execInElectronContext({setFullScreen: true});
  };

  const _setUpdateURL = (url) => {
    let data = {
      setUpdateUrl: true,
      updateURL: url
    };
    _execInElectronContext(data);
  };

  // Call global function defined in renderer of electron to communicate from webview to parent
  // see https://www.electronjs.org/docs/latest/tutorial/context-isolation#what-is-it
  let _execInElectronContext = function() {
    if (_.isFunction(_.get(obj, path))) _.spread(_.bindKey(obj, path))(arguments);
  };

  return {
    isElectron: _isElectron,
    forceQuit: _forceQuit,
    setElectronConfig: _setElectronConfig,
    setElectronFullscreen: _setElectronFullscreen,
    setFocus: _setFocus,
    setTrayIcon: _setTrayIcon,
    runExecutable: _runExecutable,
    toggleWindow: _toggleWindow,
    isWindowToggled: _isWindowToggled,
    startJitsi: _startJitsi,
    muteJitsi: _muteJitsi,
    setUpdateURL: _setUpdateURL,
    requestWebHID: _requestWebHID,
    ELECTRON_AGENT_DEFAULT_WIDTH: ELECTRON_AGENT_DEFAULT_WIDTH,
    ELECTRON_AGENT_DEFAULT_HEIGHT: ELECTRON_AGENT_DEFAULT_HEIGHT,
    ELECTRON_AGENT_MINI_WIDTH: ELECTRON_AGENT_MINI_WIDTH,
    ELECTRON_AGENT_MINI_HEIGHT: ELECTRON_AGENT_MINI_HEIGHT,
    ELECTRON_AGENT_THIRD_PARTY_MIN_WIDTH: ELECTRON_AGENT_THIRD_PARTY_MIN_WIDTH,
    ELECTRON_AGENT_QUIT_OPTIONS: ELECTRON_AGENT_QUIT_OPTIONS
  };
}
